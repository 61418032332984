import { DXAppData, OnboardingFlow, OnboardingUserState, OnboardingPagesInfo, DXAppLandingPageInfo } from "data/DXApp/DXApp";
import { fetchApi } from "./fetchStudioApi";
import { fetchDxApi, getNonAuthUserId, fetchDxApiAuthenticated, removeNonAuthUserId, getUserId } from "./fetchStudioApi";

export const getAllApps = async (): Promise<DXAppData[]> => {
    let response = await fetchDxApi({
        path: "/apps/getAllApps",
        method: "GET",
    });
    return response.response;
};

export const getAppLandingPageInfo = async (storefrontSlug: string, appSlug: string): Promise<DXAppData> => {
    let response = await fetchDxApi({
        path: `/apps/getAppLandingPageInfoBySlug/${storefrontSlug}/${appSlug}`,
        method: "GET",
    });
    return response.response;
};

export const getAppBySlug = async (storefrontSlug: string, appSlug: string): Promise<DXAppData> => {
    let response = await fetchDxApi({
        path: `/apps/getAppBySlug/${storefrontSlug}/${appSlug}`,
        method: "GET",
    });
    return response.response;
};

// Add new interface for DX Storefront data
export interface DXStorefrontData {
    slug: string;
    createdAt: string;
    ownerUserId: string;
    id: string;
    updatedAt: string;
}

export const getDXStorefrontBySlug = async (slug: string): Promise<DXStorefrontData> => {
    let response = await fetchDxApi({
        path: `/storefronts/getStorefrontBySlug/${slug}`,
        method: "GET",
    });
    return response;
};

// New onboarding API endpoints
export const getAppOnboardingFlow = async (storefrontSlug: string, appSlug: string, authUser: any = null): Promise<OnboardingFlow> => {
    // Get appropriate user ID (auth or non-auth)
    const userId = getUserId(authUser);

    let response = await fetchDxApi({
        path: `/apps/getAppOnboardingFlow/${storefrontSlug}/${appSlug}`,
        method: "GET",
        nonAuthUserId: userId,
        authUser
    });

    console.log("getAppOnboardingFlow response:", response);
    return response.response;
};

export const saveOnboardingUserState = async (
    storefrontSlug: string,
    appSlug: string,
    userState: OnboardingUserState,
    pagesInfo?: OnboardingPagesInfo,
    authUser: any = null
): Promise<{ success: boolean }> => {
    // Get appropriate user ID (auth or non-auth)
    const userId = getUserId(authUser);

    let response = await fetchDxApi({
        path: `/apps/saveOnboardingUserState/${storefrontSlug}/${appSlug}`,
        method: "POST",
        body: JSON.stringify({ userState, pagesInfo }),
        nonAuthUserId: userId,
        authUser,
        isJsonPayload: true
    });

    console.log("saveOnboardingUserState response:", response);
    return response.response;
};

export const getOnboardingUserState = async (
    storefrontSlug: string,
    appSlug: string,
    authUser: any = null
): Promise<{ userState: OnboardingUserState, pagesInfo?: OnboardingPagesInfo }> => {
    // Get appropriate user ID (auth or non-auth)
    const userId = getUserId(authUser);
    console.log("getOnboardingUserState userId: ", userId);

    let response = await fetchDxApi({
        path: `/apps/getOnboardingUserState/${storefrontSlug}/${appSlug}`,
        method: "GET",
        nonAuthUserId: userId,
        authUser
    });

    console.log("getOnboardingUserState response:", response);
    return {
        userState: response.response.userState || {},
        pagesInfo: response.response.pagesInfo || {}
    };
};

export const completeOnboarding = async (
    storefrontSlug: string,
    appSlug: string,
    finalUserState: OnboardingUserState,
    pagesInfo?: OnboardingPagesInfo,
    authUser: any = null
): Promise<{ success: boolean, redirectUrl?: string }> => {
    // Get appropriate user ID (auth or non-auth)
    const userId = getUserId(authUser);

    let response = await fetchDxApi({
        path: `/apps/completeOnboarding/${storefrontSlug}/${appSlug}`,
        method: "POST",
        body: JSON.stringify({ finalUserState, pagesInfo }),
        nonAuthUserId: userId,
        authUser,
        isJsonPayload: true
    });

    console.log("completeOnboarding response:", response);
    return response.response;
};

/**
 * Links an email address to a user's onboarding information.
 * For non-authenticated users, it will include the nonAuthUserId in the request.
 */
export const linkOnboardingWithEmail = async (
    storefrontSlug: string,
    appSlug: string,
    email: string,
    authUser: any = null
): Promise<{ success: boolean; message?: string }> => {
    const data: { email: string; nonAuthUserId?: string } = { email };

    // Get appropriate user ID (auth or non-auth)
    const userId = getUserId(authUser);
    console.log(" linkOnboardingWithEmail userId: ", userId);

    if (userId) {
        data.nonAuthUserId = userId;
    }

    console.log(" linkOnboardingWithEmail data: ", data);

    const response = await fetchDxApi({
        path: `/users/linkOnboardingWithEmail/${storefrontSlug}/${appSlug}`,
        method: "POST",
        body: JSON.stringify(data),
        nonAuthUserId: userId,
        authUser,
        isJsonPayload: true
    });

    console.log("linkOnboardingWithEmail response:", response);
    return response.response;
};

/**
 * Creates a personalized plan based on user inputs during onboarding
 */
export const createPersonalizedPlan = async (
    storefrontSlug: string,
    appSlug: string,
    userState: OnboardingUserState,
    authUser: any = null
): Promise<{ status: string; response?: { status: string; message: string; user_id: string } }> => {
    // Get appropriate user ID (auth or non-auth)
    const userId = getUserId(authUser);

    try {
        let response = await fetchDxApi({
            path: `/apps/createPersonalizedPlan/${storefrontSlug}/${appSlug}`,
            method: "POST",
            body: JSON.stringify({ userState }),
            nonAuthUserId: userId,
            authUser,
            isJsonPayload: true
        });

        console.log("createPersonalizedPlan response: ", response);

        return response;
    } catch (error) {
        console.error("Error starting personalized plan creation:", error);
        return { status: 'error' };
    }
};

interface PlanStatusResponse {
    status: "not_found" | "in_progress" | "completed" | "error";
    message?: string;
    plan?: any;
    error?: string;
    started_at?: string;
}

export const checkPlanStatus = async (
    userId: string,
    storefrontSlug: string,
    appSlug: string,
    authUser: any = null
): Promise<PlanStatusResponse> => {
    try {
        // Get appropriate user ID (auth or non-auth)
        const effectiveUserId = getUserId(authUser);

        if (!effectiveUserId) {
            throw new Error("User ID is required to check plan status");
        }

        let response = await fetchDxApi({
            path: `/apps/checkPlanStatus/${effectiveUserId}/${storefrontSlug}/${appSlug}`,
            method: "GET",
            nonAuthUserId: effectiveUserId,
            authUser
        });

        console.log("checkPlanStatus response: ", response);

        if (response.status === "completed" && response.plan) {
            // Ensure the response has all required fields for the UI
            response.plan = {
                ...response.plan,
                // Ensure essential fields exist with defaults if needed
                targetDate: response.plan.targetDate,
                programLengthWeeks: response.plan.programLengthWeeks || 6,
                timeToTargetTitle: response.plan.timeToTargetTitle || "Time to Race Day",
                timeToTargetDescription: response.plan.timeToTargetDescription ||
                    (response.plan.description ||
                        `A focused program designed to build endurance, develop strength, and prepare you to run the half marathon under 2 hours.`),
                phases: response.plan.phases || [],
            };
        }

        return response;
    } catch (error) {
        console.error("Error checking plan status:", error);
        return {
            status: "error",
            error: "Failed to check plan status"
        };
    }
};

// Define response types for payment verification
export interface PaymentVerificationResponse {
    success: boolean;
    paymentDetails?: any;
    error?: string;
    requiresVerification?: boolean;
}

export interface PaymentVerificationWrapper {
    status: string;
    response: PaymentVerificationResponse;
}

// Type that allows either direct response or wrapped response
export type PaymentVerificationResult =
    | PaymentVerificationResponse
    | PaymentVerificationWrapper;

// Verify a Stripe payment session
export const verifyPaymentSession = async (
    sessionId: string,
    verificationToken?: string
): Promise<PaymentVerificationResult> => {
    try {
        let endpoint = `/payments/verify-session/${sessionId}`;
        if (verificationToken) {
            endpoint += `?token=${verificationToken}`;
        }

        let response = await fetchDxApi({
            path: endpoint,
            method: "GET",
        });
        return response;
    } catch (error) {
        console.error("Error verifying payment session:", error);
        throw error;
    }
};

/**
 * Links an authenticated user with a previously used anonymous user ID.
 * This associates all onboarding data with the authenticated user.
 * Only links if the anonymous user has meaningful data.
 * 
 * @returns A response object with a success property that indicates if the operation was successful
 */
export const linkAuthUserWithAnonymousUserId = async (anonymousUserId: string): Promise<{
    success: boolean,
    message?: string,
    dataLinked?: boolean
}> => {
    try {
        const response = await fetchDxApiAuthenticated({
            path: "/users/linkAuthUserWithAnonymousUserId",
            method: "POST",
            body: JSON.stringify({ anonymousUserId }),
            isJsonPayload: true
        });

        // Check if we have a response and if the linking was successful
        const result = response.response || { success: false, message: "Invalid response format" };

        // If linking was successful and data was linked, we can clear the anonymous user ID
        if (result.success && result.dataLinked) {
            // Remove the anonymous user ID from local storage
            removeNonAuthUserId();
            console.log("Anonymous user ID cleared after successful linking with actual data");
        } else if (result.success && !result.dataLinked) {
            // If no data was linked (anonymous ID had no meaningful data), still clear it
            removeNonAuthUserId();
            console.log("Anonymous user ID cleared - no meaningful data was found to link");
        }

        return result;
    } catch (error) {
        console.error("Error linking anonymous user ID:", error);
        return { success: false, message: error instanceof Error ? error.message : "Unknown error" };
    }
};

/**
 * Generates a secure token for app authentication
 */
export const generateAppAuthToken = async (): Promise<{ token: string; expiresAt: string }> => {
    try {
        const response = await fetchDxApiAuthenticated({
            path: "/users/generateAppAuthToken",
            method: "POST",
            body: JSON.stringify({}),
            isJsonPayload: true
        });
        return response.response;
    } catch (error) {
        console.error("Error generating app auth token:", error);
        throw error;
    }
};

/**
 * Invalidates an app authentication token
 */
export const invalidateAppAuthToken = async (token: string): Promise<{ success: boolean }> => {
    try {
        const response = await fetchDxApiAuthenticated({
            path: "/users/invalidateAppAuthToken",
            method: "POST",
            body: JSON.stringify({ token }),
            isJsonPayload: true
        });
        return response.response;
    } catch (error) {
        console.error("Error invalidating app auth token:", error);
        throw error;
    }
};

export const getAllAppsWithOnboarding = async (): Promise<DXAppData[]> => {
    let response = await fetchDxApi({
        path: "/apps/getAllAppsWithOnboarding",
        method: "GET",
    });
    return response.response;
};

export interface WaitlistEntry {
    email: string;
    appSlug: string;
    storefrontSlug: string;
    tags: string[];
}

export const addToWaitlist = async (entry: WaitlistEntry): Promise<{ success: boolean; message?: string }> => {
    try {
        const response = await fetchDxApi({
            path: "/waitlist/add",
            method: "POST",
            body: JSON.stringify(entry),
            isJsonPayload: true
        });
        return response.response;
    } catch (error) {
        console.error("Error adding to waitlist:", error);
        return { success: false, message: "Failed to add to waitlist" };
    }
};

// New function to get landing page info with the updated structure
export const getAppLandingPageInfoV2 = async (storefrontSlug: string, appSlug: string): Promise<DXAppLandingPageInfo> => {
    let response = await fetchDxApi({
        path: `/apps/getAppLandingPageInfoV2BySlug/${storefrontSlug}/${appSlug}`,
        method: "GET",
    });
    return response.response;
};

// New function to save landing page info with the updated structure
export const saveAppLandingPageInfoV2 = async (landingPageInfo: DXAppLandingPageInfo): Promise<{ success: boolean }> => {
    let response = await fetchDxApiAuthenticated({
        path: `/apps/saveAppLandingPageInfoV2`,
        method: "POST",
        body: JSON.stringify(landingPageInfo),
        isJsonPayload: true
    });
    return response.response;
}; 